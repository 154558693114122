#pb-message-container {
  .pb-headline {
    margin: 0;
    font-family: 'Open Sans';

    &--black {
      color: black;
    }

    &--white {
      color: white;
    }
  }

  h1.pb-headline {
    font-size: 72px;
    font-weight: normal;
  }

  h2.pb-headline {
    font-size: 48px;
    font-weight: normal;
  }

  h3.pb-headline {
    font-size: 32px;
    font-weight: normal;
  }

  h4.pb-headline {
    font-size: 24px;
    font-weight: normal;
  }

  h5.pb-headline {
    font-size: 16px;
    font-weight: normal;
  }  
}
