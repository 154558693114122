@use '../../presets' as *;
@use '../../../../styles/partial/theme' as theme;

#pb-message-container {
  .pb-card {
    max-width: 100%;
    width: 100%;
    border: $thin-border;
    border-radius: theme.$bubble-chat-border-radius;
    overflow-y: hidden;

    &__image {
      width: 100%;
      border-radius: 4px 10px 4px 10px;
    }

    &__label {
      border: $thin-border;
      border-left: none;
      border-right: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //max-height: 66px;
      min-height: 42px;
      padding: 0 8px;
    }

    &__title {
      font-size: 24px;
      color: black;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
      width: 100%;
      font-weight: 900;
      padding-top:4px;
      padding-bottom:2px;
    }

    &__subtitle {
      font-size: 15px;
      color: #aaa;
      width: 100%;
      padding-top:4px;
      padding-bottom:4px;
      font-weight: 700;
    }

    &__buttons {
      display: flex;
      flex-direction: column;

      button {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal;
        padding: 10px 0;
        font-size: $button-font-size;
        background-color: white;
        cursor: pointer;
      }

      button:not(:first-child) {
        border-top: $thin-border;
      }

      button:last-child {
        border-radius: 0 0 theme.$bubble-chat-border-radius theme.$bubble-chat-border-radius;
      }
    }
  }
}
