@use '../../../styles/partial/theme' as theme;

.modal-wrapper {
    transition: all 0.3s ease-in-out;
    width: 100%;
    height: 100%;
    position:absolute;
    opacity: 1;
    display:flex;
}

@keyframes fade-in {
    0% {opacity: 0;}
    50% {opacity: 0.6;}
    100% {display: flex;}
}
@keyframes fade-out {
    0% {opacity: 0.6;}
    50% {opacity: 0;}
    100% { display:none;}
}



.modal {
    position:fixed;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width:100%;
    top: 0;
    bottom: 0;
    right: 0px;
    left: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 904;//theme.$z-index-reaction-modal;
}

