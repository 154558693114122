@use '../../../styles/partial/theme' as theme;

#pb-message-container {
  .ellipsis{
    display:block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
  }

  .pb-bot-response--log {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .metadata {
      padding: 10px;

      &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        >a {
          color: #d3d3d3;
          font-weight: bold;
          text-decoration: underline;

          &:hover {
            color: #9A9A9A;
            text-decoration: none;
          }
        }

        > *:not(:first-child) {
          padding-left: 8px;
        }
      }
    }

    .searchresult {
      border: 3px solid #5cb85c;
    }

    .chatpriority {
      border: 3px solid #FF9A46;
    }

    .bubble-with-avatar {
      display: flex;
      align-items: center;
    }

    .pb-quickReply__container, .pb-buttonList__container .pb-button--postback, .pb-carousel .pb-card .pb-button--postback{
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }


    .pb-carousel, .pb-card, .pb-fullSizeImage, .pb-buttonList__container {
      width: 300px;

    }

    .pb-carousel .pb-card {
      margin-left: 0;
      margin-bottom: 0;
    }

    .chat-message {
      display: flex;
    }

    img.pb-bot-avatar {
      width: 40px;
      height: 40px;
    }

    .chat-message--bot, .chat-message--agent {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .chat-bot-message, .chat-human-message, .chat-agent-message {
      clear: both;
      padding: 10px;
      margin: 10px;
      border-radius: 20px;
      color: white;
    }

    .chat-bot-message {
      background: #7c4dff;
      float: right;
    }

    .chat-agent-message {
      background: #EB8A51;
      float: right;
    }

    .chat-human-message {
      background: #eeeeee;
      color: black;
      float: left;
    }

    .pb-fullSizeImage {
      border-radius: 8px;
    }

    .pb-message__bot-message-edit {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .pb-typingOn {
      margin: 0;
      width: 200px;
      justify-content: center;
      padding: 35px 30px;
    }
  }

  .pb-bot-response {
    &:not(:last-child) {
      .pb-message {
        .pb-message__reply-menu, .pb-quickReply__container {
          button{
            cursor:not-allowed;
          }
        }
      }
    }
  }

  .pb-bot-response--log {
    &:not(:last-child) {
      .pb-message {
        .pb-message__reply-menu, .pb-quickReply__container {
          display: flex !important;
        }
      }
    }
  }
}
