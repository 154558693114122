//--------------------
//  Color
//--------------------
$main-brand: #24cfdb;
$main-brand-light: #6adee7;

$brand-dark: #129da0;
$main-black: #111;
$main-white: #fff;
$main-brand-light-gray:#d1e9eb;
$medium-gray: #dbdbdb;
$dark-gray: #555;
$user-chat-gray: #e6e6ea;
$main-font: "Noto Sans", sans-serif;

$baby-blue: #d8f6f9;
$baby-blue-darker: #a7ebf1;

$top-offset-mobile-portrait: 100px;
$bottom-offset-mobile-portrait: 0px;

//--------------------
//  Header
//--------------------
$header-height:60px;

$chat-panel-height-less-header:calc(100% - 60px);

//--------------------
//  Border radius
//--------------------
$bubble-chat-border-radius: 12px;
$bubble-button-border-radius: 4px;
$button-send-border-radius: 10px;

//--------------------
//  Max Image/ Carousel Width
//--------------------
$max-image-width: 360px;

//--------------------
//  Paddings
//--------------------
$button-input-border-radius: 10px;

//--------------------
//  Avatar
//--------------------
$avatar-width: 40px;
$avatar-margin: 10px;
$avatar-offset: $avatar-width + ($avatar-margin * 2);

//--------------------
//  Message Container
//--------------------
$message-padding-top: 6px;

$message-response-group-margin-top: 20px;
//----------------
//  Buttons
//----------------
// button list
$button-margin-right: 5px;
$button-gap: 5px;

// send button
$button-send-padding: 14px;

// Carousel button
$button-carousel-width: 45px;
$button-carousel-height: 45px;

$button-carousel-more-button-padding: 23px;

// postback buttons
$button-postback-border-radius-bottom-right:4px;
$button-postback-border-radius:12px;


//----------------
//  Carousel
//----------------
$carousel-padding-v: 0.7em;
$carousel-padding-h: 1.2em;
$carousel-title: 18px;
$carousel-title-padding-bottom: 0.4em;
$carousel-line-height: $carousel-title;
//----------------
//  Chat bubble
//----------------
// user chat bubble
$bubble-user-margin: 10px;

// Max width
$bubble-max-width-mobile-portrait: calc(87% - 70px);
$bubble-max-width-mobile-landscape: calc(82% - 70px);
$bubble-max-width-tablet-portrait: calc(78% - 70px);
$bubble-max-width-desktop: calc(72% - 70px);

// Padding
$bubble-padding-v: 0.5em;
$bubble-padding-h: 1.2em;

$message-button-padding-v: 1em;
$message-button-padding-h: 1em;

$message-input-group-height: 60px;
$message-input-group-height-lg: 80px;

$message-input-padding-v: 0.8em;
$message-input-padding-h: 1em;

//----------------
//  Z-index
//----------------

$z-index-input-footer:900;
$z-index-menu-content:901;
$z-index-menu-header:902;
$z-index-reaction-panel:903;
$z-index-reaction-modal:904;
$z-index-reaction-panel-active:905;
$z-index-left-column:906;
$z-index-top-nav:907;
$z-index-cookie-panel:907;
$z-index-menu-profile:1000;
$z-index-modal:909;
$z-index-sentry-feedback-panel:999;
$z-index-loader:2000;


//----------------
//  OAuth Brands
//----------------

// Google
$brand-google-blue:#4285f4;

$google-up-bg-color:$brand-google-blue;
$google-hover-bg-color:rgba($brand-google-blue, 0.9);
$google-hover-box-shadow-color:rgba($brand-google-blue, 0.3);
$google-active-bg-color:rgba($brand-google-blue, 0.8);

// Facebook
$brand-facebook-blue:#4267b2;

$fb-up-bg-color:$brand-facebook-blue;
$fb-hover-bg-color:rgba($brand-facebook-blue, 0.9);
$fb-hover-box-shadow-color:rgba($brand-facebook-blue, 0.3);
$fb-active-bg-color:rgba($brand-facebook-blue, 0.8);
$brand-facebook-blue:#4267b2;

// Tiktok
$brand-tiktok-color:#000;

$tt-up-bg-color:$brand-tiktok-color;
$tt-hover-bg-color:rgba($brand-tiktok-color, 0.9);
$tt-hover-box-shadow-color:rgba($brand-tiktok-color, 0.3);
$tt-active-bg-color:rgba($brand-tiktok-color, 0.8);


@mixin get-viewport-percent-width($percent: 100vw ) {
  width: calc(#{$percent} - 10px - (#{$message-input-padding-v} * 2) );
}




//--------------------
//  Themes
//--------------------

$theme-colors:(
        'primary':(
                'base':$main-brand,
                'light':'',
                'dark':''
        ),
        'secondary':(
                'base':'',
                'light':'',
                'dark':''
        ),
        'accent':(
                'base':'',
                'light':'',
                'dark':''
        ),
        'forground':(
                'base':'',
                'light':'',
                'dark':''
        ),
        'background':(
                'base':'',
                'light':'',
                'dark':''
        )
);

$contrast-colors: (
        'dark': (
                'primary': rgb(255, 255, 255),
                'secondary': rgba(255, 255, 255, .70),
                'disabled': rgba(255, 255, 255, .5),
                'hint': rgba(255, 255, 255, .12)
        ),
        'light': (
                'primary': rgba(0, 0, 0, .87),
                'secondary': rgba(0, 0, 0, .54),
                'disabled': rgba(0, 0, 0, .38),
                'hint': rgba(0, 0, 0, .12)
        ),
);

@function theme-color($key:'primary', $variant:'base'){
  $map: map-get($theme-colors, $key);
  @return map-get($map, $variant)
}