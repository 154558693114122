@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes fade-out {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotation-lr {
  0% {
    transform: rotate(-90deg);
  } 100% {
      transform: rotate(0);
    }
}

@-webkit-keyframes rotation-lr {
  0% {
    transform: rotate(-90deg);
  } 100% {
      transform: rotate(0);
    }
}

@-moz-keyframes rotation-lr {
  0% {
    transform: rotate(-90deg);
  } 100% {
      transform: rotate(0);
    }
}

@keyframes rotation-rl {
  0% {
    transform: rotate(180deg);
  } 100% {
      transform: rotate(0);
    }
}

@-webkit-keyframes rotation-rl {
  0% {
    transform: rotate(180deg);
  } 100% {
      transform: rotate(0);
    }
}

@-moz-keyframes rotation-rl {
  0% {
    transform: rotate(180deg);
  } 100% {
      transform: rotate(0);
    }
}

@keyframes bounceIn {
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}

@keyframes bubble1 {
  0% {
    top: 0px;
  }
  15% {
    top: -10px;
  }
  25% {
    top: 0px;
  }
}

@-webkit-keyframes bubble1 {
  0% {
    top: 0px;
  }
  15% {
    top: -10px;
  }
  25% {
    top: 0px;
  }
}

@-moz-keyframes bubble1 {
  0% {
    top: 0px;
  }
  15% {
    top: -10px;
  }
  25% {
    top: 0px;
  }
}
@keyframes bubble2 {
  10% {
    top: 0px;
  }
  25% {
    top: -10px;
  }
  35% {
    top: 0px;
  }
}

@-webkit-keyframes bubble2 {
  10% {
    top: 0px;
  }
  25% {
    top: -10px;
  }
  35% {
    top: 0px;
  }
}

@-moz-keyframes bubble2 {
  10% {
    top: 0px;
  }
  25% {
    top: -10px;
  }
  35% {
    top: 0px;
  }
}
@keyframes bubble3 {
  15% {
    top: 0px;
  }
  30% {
    top: -10px;
  }
  40% {
    top: 0px;
  }
}

@-webkit-keyframes bubble3 {
  15% {
    top: 0px;
  }
  30% {
    top: -10px;
  }
  40% {
    top: 0px;
  }
}

@-moz-keyframes bubble3 {
  15% {
    top: 0px;
  }
  30% {
    top: -10px;
  }
  40% {
    top: 0px;
  }
}
