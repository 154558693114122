@use '../presets.scss' as *;
@use '../../../styles/partial/mixin' as mixin;
@use '../../../styles/partial/media' as media;

%sent-message {
  float: right;
  background-color: $pb-purple;
  color: $white;
}

%received-message {
  float: left;
  background-color: $light-gray;
  color: $black;
}

#pb-message-container {
  .pb-chat-bubble {
    border-radius: 20px;
    padding: 4px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    .typing-bubble{
      &-wrapper {
        position: relative;
        text-align: center;
      }
      &-message{
        transition: all 0.3s ease-in-out;
        &.show{
          transform:translateY(2);
          opacity:1;
        }
        &.hide{
          transform:translateY(-2);
          opacity:0;
        }
      }
    }


    @include mixin.disable-user-select();


    br {
      margin-bottom: 4px !important;
    }

    &__user {
      @extend %sent-message;

      .fa-sitemap { margin-left: 5px; }
    }

    &__agent {
      @extend %received-message;
    }

    &__bot {
      @extend %received-message;

      &:hover{
        cursor:pointer;
      }
    }
    &__earn-coin {
      position: absolute;
      right: -32px;
      align-self: center;
      color: #f7c139;
      font-weight: 700;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      animation-name: fadeInOut;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-timing-function: cubic-bezier(0, 1.1, 0.8, 4);
      pointer-events: none;
      .coins{
        display:inline-block;
        margin-left:4px;
      }

    }
    &-wrapper {
      display: flex;
      width: 100%;
      clear: both;
      position: relative;

      &--bot-view {
        .pb-chat-bubble {
          &__user {
            @extend %received-message;
          }

          &__bot {
            float: right;
            background-color: $light-purple;
            color: $black;
          }

          &__agent {
            @extend %sent-message;
          }

          &--edited {
            border: 2px dashed $pb-purple;
          }
        }
      }
    }
  }
}


@-webkit-keyframes fadeInOut {
  0% {opacity: 0; transform: translateX(2px);}
  10% { opacity: 1; transform: translateX(3px);}
  80% { opacity: 1; }
  92% { transform: translateX(4px); opacity: 0;}
  100% { opacity: 0; display:none}
}

@keyframes fadeInOut {
  0% {opacity: 0; transform: translateX(2px);}
  10% { opacity: 1; transform: translateX(3px);}
  80% { opacity: 1; }
  92% { transform: translateX(4px); opacity: 0;}
  100% { opacity: 0; display:none}
}