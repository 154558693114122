@use '../../../styles/partial/mixin' as mixin;
@use '../../../styles/partial/theme' as theme;
@use '../../../styles/partial/media' as media;

.pb-message {
  &.active{
    .chat-reaction{
      opacity: 1;
      visibility: visible;
      justify-content: space-between;
      display: flex;
      top: -42px;
      background:white;
      border-radius:14px 14px 4px 14px;
      border:2px solid theme.$main-brand;
      box-shadow: 0 8px 10px 4px rgb(0 0 0 / 22%);
    }
  }

    //Chat reaction panel with Like and Dislike Buttons
    .chat-reaction{
      transition: all 0.3s ease-in-out;
      position: absolute;
      left:-50px;
      right:initial;
      top: -30px;
      display:flex;
      justify-content: space-around;
      min-width:240px;
      opacity: 0;
      visibility: hidden;

      hr{
        width:0px;
        height:20px;
        align-self:center;
        background-color: #ccc;
        border: none;
      }

      &.show{
        opacity: 1;
        visibility: visible;
        transform:translateY(-6px);
      }
      //
      &.hide{
        z-index:1;
        animation: hide 0.2s;
        transform: scale(0);
        color:red;
      }

      &.active{
        z-index: theme.$z-index-reaction-panel;
      }

      button{
        @include mixin.button-style-base();
        border-radius: 50%;
        background: transparent;
        border:1px solid transparent!important;
        cursor:pointer;
        transition:all 0.3s;
        transform: scale(1);
        cursor:pointer;
        &:hover{
          transform: scale(2);
          background: transparent;
          border-radius:50px;
          padding:0;

        }

        &.icon{
          padding:2px 10px;

          img{
            width:26px !important;
            height:26px !important;
          }

          i{
            font-size:20px;
            color:theme.$main-brand;
          }

          @include media.max-mobile-portrait {
            padding:2px 12px;
          }

          @include media.max-mobile-portrait-mini {
            width:16px;
            padding:2px 8px;
            img{
              width:22px !important;
              height:22px !important;
            }

            i{
              font-size:18px;
              color:gray;
            }
          }
        }
      }

      &.training{
        transform: translateY(-30px);
        left:initial;
        right:0;
        top:0;
        min-width:initial;
        width:80px;
        button{
          padding: initial;
          &:hover{
            transform: scale(1.2);
          }
          &.icon {
            padding: initial;
            img{
              width:48px !important;
              height:48px !important;
            }

          }
        }
      }
    }

  .chat-feedback-icon {
    transition: all 0.3s ease-in-out;
    cursor: default;
    position: absolute;
    right: -40px;
    transform: translateY(-50%);
    background: transparent;
    opacity:0;
    pointer-events: none;
    &.show{
      animation-duration: 0.3s;
      animation-name: bounceIn;
      animation-fill-mode: forwards;
    }

    &:hover{
      background: red;
    }

    .emoji {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid black;
      position: relative;
      color: #131212;
      pointer-events: none;
      width: 36px;
      height: 36px;
      border:none;
      top:2px;
      img {
        width: 36px;
        height: 36px;
      }
    }
  }


      // Reaction icon to show selected reaction
    .chat-reaction-icon{
      position: absolute;
      right: 0;
      bottom:-15px;
      transition: all 0.3s ease-in-out;
      cursor:default;

      &.show{
        animation-duration: 0.3s;
        animation-name: bounceIn;
        animation-fill-mode: forwards;
      }
      .emoji {
        display: flex;
        padding: 1rem;
        align-items: center;
        justify-content: center;
        border: 1px solid black;
        position: relative;
        color: #131212;
        background:transparent;
        border: 2px solid white;
        border-radius: 50%;
        cursor: default;
        outline: none;
        box-shadow: 0 2px 4px 2px rgb(0 0 0 / 15%);
        width: 24px;
        height: 24px;

        img{
          width:20px !important;
          height:20px !important;
        }

        i{
          font-size:18px;
          color:#333;
        }

      }
    }
  }

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}