@use '../../presets' as *;

#pb-message-container {
  .pb-carousel {
    max-width: 95%;


    &__card {
      max-width: 100%;
    }

    &__next, &__previous {
      width: 30px;
      height: 30px;
      font-weight: bold;
      background-color: white;
      border: $thin-border;
      border-radius: 10px;
      margin-top: 2px;
      cursor: pointer;
    }

    &__next {
      float: right;
    }

    &__previous {
      float: left;
    }
  }
}
