@use '../../../styles/partial/theme' as theme;
@import '../../../styles/partial/animations';

#loader-wrapper{
  display:flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: theme.$z-index-loader;
  -webkit-animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  transition: all 0.5s ease-in-out;

  &.circle-loader {
    align-self: center;
    margin-left: 10px;
    transition: all 0.3s ease-in-out;
    width: 36px;
    height: 32px;
    opacity: 0;
    display: flex;
    align-items: center;

    .loader-wrapper__inner{
      width: 100%;
      height:100%;
      border-radius: 50px;
      background-color: white;
      border: 2px solid theme.$main-brand;
      border-radius: 50px;
      display:flex;
      align-items: center;
      .bubble-loader {
        background-color: theme.$main-brand;
        width: 5px;
        height: 5px;
      }
    }

    &.show{
      opacity:1;
      transform:translateX(0px);
      visibility: visible;
    }

    &.hide{
      opacity:0;
      transform:translateX(-10px);
      visibility: hidden;
    }
  }


  &.not-covered{
    margin-top:2em;
    background-color: transparent;

    .loader-wrapper__inner .bubble-loader {
      background:   theme.$main-brand;
    }
  }

  &.cover{
    flex-direction: column;
    position:absolute;
    left:0;
    bottom: 0;
    top:0;
    right:0;
    z-index: theme.$z-index-loader;
    background-color: theme.$main-brand;

    .loader-wrapper__inner .bubble-loader {
      background:  white;
    }

    &.avatar-main{
      transition: all 0.3s ease-in-out;
      border-radius:50%;
      background-color: #d1e9eb;
      color:black;
      z-index:0;
    }
  }

  &.fadeIn{
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.4s;
  }

  &.fadeOut{
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-delay: 0.8s;
    -webkit-animation-duration: 0.5s;
  }

  p{
    font-family: "Noto Sans", --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    font-size: 15px;
  }

  .loader-wrapper__inner{
    width: 60px;
    height: 35px;
    border-radius: 35%;
    display: flex;
    align-items: center;
    justify-content: center;

    .bubble-loader {
      position: relative;
      height: 8px;
      width: 8px;
      margin: 0 2px;
      background:  white;
      display: inline-block;
      border-radius: 50%;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    #pb-bubble1 {
      animation-name: bubble1;
    }

    #pb-bubble2 {
      animation-name: bubble2;
    }

    #pb-bubble3 {
      animation-name: bubble3;
    }
  }
}

@-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  80% {opacity: 0;    }
  100% {height:0; top:-150vh; display:none; }
}

@keyframes fadeOut {
  0% {opacity: 1;}
  80% {opacity: 0; }
  100% {height:0;  top:-150vh;display:none;}
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  10% {opacity: 1;    top:0;}
  100% {height:initial; display:flex;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  10% {opacity: 1;     top:0;}
  100% {height:initial; display:flex;}
}
