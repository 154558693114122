@use '../presets' as *;
@use '../../../styles/partial/theme' as theme;

#pb-message-container {
  .pb-message {
    margin: 5px 10px;
    display: flex;
    font-family: $primary-font;
    font-size: $primary-font-size;
    justify-content: space-between;
    max-width: 300px;
    position: relative;


    ol, ul {
      margin: 0;
      padding: 6px 0 6px 15px;
    }

    img, video {
      //width: 90%;
    }

    &__clickable-bubble {
      cursor: pointer;
    }

    .pb-fullSizeImage {
      width: 100%;
    }

    &__avatar {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 10px;
    }

    blockquote {
      margin: 0;
      margin-left: 10px;
      margin-top: 4px;
      padding: 0 10px;
      border-left: $thin-border;
    }

    a {
      color: $pandorabots-color;
      text-decoration: none;
    }

    &__button-menu, &__reply-menu, &__media {
      padding: 0;
      background-color: transparent;
    }

    &__button-menu {
      margin-left: 60px;
      margin-top: -5px;
      font-size: $button-font-size;
    }

    &__reply-menu {
      max-width: 100%;
      width: 100%;
      margin-top: -4px;
      font-size: $reply-font-size;
    }

    &__card {
      background-color: transparent;
      padding: 0;
      width: 300px;
      max-width: 300px;
    }

    &__content{
      display:flex;
      flex-direction: row;
      align-items: center;
  }
}
}