@use '../../../../styles/partial/media' as media;
@use '../../../../styles/partial/theme' as theme;

*, *:before, *:after {
  box-sizing: border-box;

}

.facebook-sign-in-button {
  margin: 0 0 0 0;
  display: inline-block;
  height: 46px;
  background-color: theme.$fb-up-bg-color;
  color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  text-align: left;
  width:100%;

  &:hover{
    cursor: pointer;
    background-color: theme.$fb-hover-bg-color;
    -webkit-box-shadow: 0 0 3px 3px rgba( theme.$fb-hover-box-shadow-color, 0.3);
    box-shadow: 0 0 3px 3px rgba( theme.$fb-hover-box-shadow-color, 0.3);
  }

  &:active{
    background-color: theme.$fb-active-bg-color;
    transition: background-color 0.2s;
  }

  &:focus{
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px 4px theme.$fb-hover-box-shadow-color;
    box-shadow: 0 0 3px 4px  theme.$fb-hover-box-shadow-color;
  }
}

.facebook-sign-in-button .content-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
}

.facebook-sign-in-button img {
  width: 20px;
}

.facebook-sign-in-button .logo-wrapper {
  padding: 10px;
  background: #4267b2;
  width: 46px;
  height: 100%;
  border-radius: 2px;
  display: inline-block;
}

.facebook-sign-in-button .text-container {
  font-family: Roboto,arial,sans-serif;
  font-weight: 500;
  letter-spacing: .21px;
  font-size: 16px;
  line-height: 44px;
  vertical-align: top;
  border: none;
  text-align: center;
  padding-left:6px;

  @media(min-width: 320px) and (max-width: 419px){
    padding-left:10px;
    font-size:15px;
    display: inline-block;
  }

  @media(min-width: 420px){
    padding-left:20px;
    font-size:16px;
    display: inline-block;
  }

}
