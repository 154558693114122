@import '../presets';
@import '../../../styles/partial/animations';

#pb-message-container {
  .pb-typingOn {
    margin: 0;
    height: initial;
    transition: all 0.3s ease-in-out;
    border-radius: 35%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.show{

    }
    &.hide{

    }
    &:before{

    }

    &:after{

    }
    &.in-bubble{
      margin: 0;
      height: initial;
      position: absolute;
      left:0;
      right:0;
      top:0;
      bottom:0;
      transition: all 0.3s ease-in-out;
      &.hide{
        display:none;
      }
      &.show{
        display:block;
      }
    }

    &__bubble {
      position: relative;
      height: 6px;
      width: 6px;
      margin: 0 2px;
      background: $border-color;
      display: inline-block;
      border-radius: 50%;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    #pb-bubble1 {
      animation-name: bubble1;
    }

    #pb-bubble2 {
      animation-name: bubble2;
    }

    #pb-bubble3 {
      animation-name: bubble3;
    }

  }
}
