@use '../../../styles/partial/theme' as theme;
@import '../../../styles/partial/animations';

#more-button__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: theme.$z-index-reaction-panel + 1;
  -webkit-animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 36px;
  height: 36px;
  opacity: 0;
  border-radius: 50px;
  background-color: white;
  border: 2px solid theme.$main-brand;
  box-shadow: 1px 0 2px 4px rgba(36,207,219,0.1);

  &:hover{
    box-shadow: 1px 0 20px 8px rgba(36,207,219,0.1);
    background-color: theme.$main-brand;
    cursor:pointer;
    transition:all 0.3s;
    i{
      color:white;
    }
  }

  i{
    font-size:1.2em;
    color: theme.$main-brand;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(4px);
  }

  &.hide {
    opacity: 0;
    transform: translateX(-10px);
    visibility: hidden;
  }
}
