
#pb-message-container {
  .pb-codeBlock {
    color: grey;
    padding: 0;

    &__tab {
      padding-left: 10px;
    }
  }  
}
