@use '../../../styles/partial/theme' as theme;

.spinner-container{
  background-color:transparent;
  position:absolute;
  display:flex;
  align-items:center;
  justify-content: center;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index: theme.$z-index-menu-content;
  @keyframes hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display:none;
    }
  }

  &.hide{
    animation: hide 1s ease-in;
  }
}