@use '../../presets' as *;
@use '../../../../styles/partial/media' as media;
@use '../../../../styles/partial/theme' as theme;
@use '../../../../styles/partial/mixin' as mixin;

#pb-message-container {
  .pb-quickReply {
    padding: 6px;
    min-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 20px;
    background-color: white;
    border: 1px solid;
    text-align: center;
    font-size: $reply-font-size;
    cursor: pointer;

    &__container {
      @include mixin.button-container-style();

      &--fullSize {
        flex-direction: column;
        align-items: center;

        button {
          width: 250px;
        }
      }
    }
  }
}
