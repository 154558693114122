@use '../../../styles/partial/theme' as theme;

.thought-bubble {
  display:flex;
  background-color: transparent;//theme.$main-brand;
  border-radius:30px;
  //min-width:80px;
  max-width:220px;
  min-height:40px;
  margin-left:20px;
  position:relative;
  align-items:center;
  justify-content:center;
  text-align:center;
}
.thought-bubble:after {
  content:"";
  background-color: rgba(36,207,219,90%);
  border-radius:50%;
  display:block;
  position:absolute;
  z-index:1;
}


.thought-bubble:after {
  bottom: 1px;
  left: -12px;
  width: 20px;
  height: 20px;
  box-shadow: -16px 7px 0 -6px  rgba(36,207,219,100%);
  z-index: theme.$z-index-reaction-panel-active;
}
