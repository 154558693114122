@use '../../../styles' as *;

.lazy-image__container{
  position:relative;
  width:fit-content;
  margin-left:auto;
  margin-right:auto;
  border-radius: 8px;

  .lazy-image{
    display: block;
    max-width:100%;
    max-height:100%;
    object-fit: cover;
    //max-height:100%;
    border-radius: inherit;

    &.full-size{
      width: 100%;
    }
    &.grayscale{
      filter: grayscale(0.85);
    }

    &.rounded{
      border-radius: 50%;
      border: 8px solid transparent;
      transition: all 0.3s ease-in-out;
    }
    &.show-default-image{
      opacity: 1;
    }
    opacity: 0;
    @keyframes loaded {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &.loaded:not(has-error) {
      animation: loaded 0.4s ease-in;
      animation-fill-mode: forwards;
    }

    &.has-error {
      animation: loaded 1s ease-in;
      animation-fill-mode: forwards;
      content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=');
    }
  }
}
