// ---------------------
//   Media query  
// ---------------------
$mobile-portrait-tiny-width: 320px;
$mobile-portrait-mini-width: 377px;
$mobile-portrait-width: 420px;
$mobile-landscape-mini-width: 560px;
$mobile-landscape-width: 768px;
$tablet-portrait-width: 1024px;
$desktop-width: 1365px;

// TODO: refactor media query

@mixin mobile-portrait{
  // 420px maximum width: cover all smartphones in portrait mode
  @media (max-width:#{$mobile-portrait-width}){
    @content;
  }
}
@mixin mobile-landscape{
  // 421px to 767px width: most smartphones in landscape mode
  @media(min-width:#{$mobile-portrait-width + 1}) and (max-width:#{$mobile-landscape-width}){
    @content;
  }
}

@mixin tablet-portrait{
  //768px to 1023px: most Android tablets and iPads in portrait mode
  @media(min-width: #{$mobile-landscape-width + 1}) and (max-width:#{$tablet-portrait-width}){
    @content;
  }
}

@mixin tablet-landscape{
  // 1024px to 1365px: most Android tablets and iPads in landscape mode, older desktop/laptop monitors
  @media(min-width: #{$tablet-portrait-width + 1}) and (max-width:#{$desktop-width}){
    @content;
  }
}
@mixin desktop{
  // 1366px and above: iPad PRO, HDready and FullHD desktop laptop monitors
  @media(min-width:#{$desktop-width}){
    @content;
  }
}

  //--------------
  // max and below
  //--------------
  
  @mixin max-mobile-portrait-mini{
     // 377 and below
    @media(max-width:#{$mobile-portrait-mini-width}){
        @content;  
    }
  }

  @mixin max-mobile-portrait{
     // 419px and below
    @media(max-width:#{$mobile-portrait-width}){
        @content;
    }
  }

  @mixin max-mobile-landscape{
     // 767px and below
    @media(max-width:#{$mobile-landscape-width}){
        @content;  
    }
  }

@mixin max-mobile-landscape{
  // 767px and below
  @media(max-width:#{$mobile-landscape-width}){
    @content;
  }
}

@mixin max-tablet-portrait{
  // 1023px and below
  @media(max-width:#{$tablet-portrait-width}){
    @content;
  }
}


  //------------------
  //  min and above
  //------------------
  @mixin min-mobile-portrait-tiny{
    // 320px and above
   @media(min-width:#{$mobile-portrait-tiny-width}){
       @content;
   }
 }
  @mixin min-mobile-portrait-mini{
    // 377px and above
   @media(min-width:#{$mobile-portrait-mini-width}){
       @content;
   }
 }
  @mixin min-mobile-portrait{
    // 420px and above
   @media(min-width:#{$mobile-portrait-width}){
       @content;
   }
 }

 @mixin min-mobile-landscape-mini{
    // 560px and above
   @media(min-width:#{$mobile-landscape-mini-width}){
       @content;
   }
 }

 @mixin min-mobile-landscape{
    // 768px and above
   @media(min-width:#{$mobile-landscape-width}){
       @content;
   }
 }

 @mixin min-tablet-portrait{
    // 1023px and above
   @media(min-width:#{$tablet-portrait-width}){
       @content;  
   }
 }
  

  