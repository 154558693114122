@use "media" as media;
@use "theme" as theme;

//--------------------
//  Message Container
//--------------------
@mixin responsive-width($prop) {
  @include media.mobile-portrait {
    #{$prop}: theme.$bubble-max-width-mobile-portrait;
  }

  @include media.mobile-landscape {
    #{$prop}: theme.$bubble-max-width-mobile-landscape;
  }

  @include media.tablet-portrait {
    #{$prop}: theme.$bubble-max-width-mobile-landscape;
  }

  @include media.tablet-landscape {
    #{$prop}: theme.$bubble-max-width-tablet-portrait;
  }

  @include media.desktop {
    #{$prop}: theme.$bubble-max-width-desktop;
  }
}

//------------------------
//  Button Style
//------------------------
@mixin button-style-base() {
  background-color: theme.$main-white;
  color: theme.$main-brand;
  border: 1px solid theme.$main-brand;
  padding: theme.$message-button-padding-v theme.$message-button-padding-h;
  flex: 1 1 auto;
  white-space: nowrap;
  border-radius: theme.$bubble-button-border-radius;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e5f9fb;
    color: #22c3ce;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 5%);
  }
}

@mixin button-style($type) {
  @if $type == "quick-reply" {
    //  1365 and up
    @include button-style-base();
    border-radius: theme.$bubble-chat-border-radius theme.$bubble-chat-border-radius 4px theme.$bubble-chat-border-radius;
    flex-grow: 0;

    &.disabled {
      display: none;
      border: 1px solid #d4e7e8;
      color: #c6d1d2;

      &:hover {
        background-color: white;
      }
    }

    @include media.mobile-portrait {
      flex-basis: 100%;
    }

    @include media.mobile-landscape {
      flex-grow: 0;
    }

    @include media.tablet-portrait {
      flex-basis: 100%;
    }
    @include media.tablet-landscape {
      flex-grow: 0;
    }

  } @else if $type == "postback" {
    @include button-style-base();
    border-radius: theme.$bubble-chat-border-radius theme.$bubble-chat-border-radius 4px theme.$bubble-chat-border-radius;
    flex-basis: 100%;

  } @else if $type == "url" {
    @include button-style-base();
    position: relative;
    i {
      position: absolute;
      bottom: 6px;
      right: 6px;
      font-size: 1.2rem;
      opacity: 0.5;
    }

  } @else if $type == "carousel" {
    @include button-style-base();

    bottom: 10px;
    position: absolute;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 5%);
    padding: 0;
    width: theme.$button-carousel-width;
    height: theme.$button-carousel-height;
    border: 1px solid theme.$baby-blue-darker !important;

    @include media.max-mobile-portrait {
      width: initial;
      height: initial;
      padding: 4px 10px;
    }

  } @else if $type == "button" {
    @include button-style-base();
  }
}

//--------------------------
//  Button Container Style
//--------------------------
@mixin button-container-style() {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: theme.$button-gap;
  max-width: theme.$max-image-width;
  width: 60%;
  @include responsive-width("width");

  .pb-widget__urlButton {
    @include button-style('url');
  }
  .pb-quickReply {
    @include button-style('quick-reply');
  }
  .pb-button--postback {
    @include button-style('postback');
  }
}

@mixin chat-history-width($view) {
  @if $view == "min-tablet-portrait" {
    width: calc(70vw - 10px - (#{theme.$message-input-padding-v} * 2));
  } @else if $view == "max-tablet-portrait" {
    width: calc(50vw - 10px - (#{theme.$message-input-padding-v} * 2));
  } @else if $view == "max-mobile-landscape" {
    width: calc(100vw - 10px - (#{theme.$message-input-padding-v} * 2));
  }
}


@mixin chat-panel-height($view) {
  @if $view == "min-tablet-portrait" {
    padding-top: 0;
    height: 100%;
  } @else if $view == "max-tablet-portrait" {
    padding-top: 0;
    height: 100%;
  } @else if $view == "max-mobile-landscape" {
    padding-top: 75px;
    height: calc(100% - 75px);
  }
}

@mixin pseudo-element($element) {
  &:#{$element}, &::#{$element} {
    @content
  }
}

@mixin brand-scrollbar() {
  //for mozilla
  scrollbar-color: red green;
  scrollbar-width: thin;

  @include pseudo-element('-webkit-scrollbar') {
    width: 13px;
    height: 10px;
  }
  @include pseudo-element('-webkit-scrollbar-track') {
    background: theme.$main-white;
  }

  @include pseudo-element('-webkit-scrollbar-thumb') {
    height: 4px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: theme.$user-chat-gray;
  }
  @include pseudo-element('-webkit-scrollbar-button') {
    width: 0;
    height: 0;
    display: none;
  }
  @include pseudo-element('-webkit-scrollbar-corner') {
    background-color: transparent;
  }
}


@mixin firefox-scrollbar-styling() {
  scrollbar-color: theme.$user-chat-gray theme.$main-white !important;
  scrollbar-width: thin !important;
}


@mixin disable-user-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

@mixin login-button-style($variance) {
  transition: background-color .218s, color 0.218s, border-color .218s, box-shadow .218s;
  font-family: Roboto, arial, sans-serif;
  font-weight: 600;
  letter-spacing: .21px;
  font-size: 16px;
  width: 100%;


  @if $variance == 'primary' {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background-color .218s, color 0.218s, border-color .218s, box-shadow .218s;
    font-family: Roboto, arial, sans-serif;
    font-weight: 600;
    letter-spacing: .21px;
    border-radius: theme.$bubble-chat-border-radius theme.$bubble-chat-border-radius theme.$bubble-chat-border-radius 4px;
    background-color: theme.$main-brand;
    color: theme.$main-white;
    //padding: 14px 30px ;
    border: 1px solid transparent;
    width: 100%;
    &:disabled {
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
      -webkit-box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
      box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
      background-color: #22c7d3;
    }

    &:active {
      background-color: rgba(#21beca, 0.9);
      transition: background-color 0.2s;
    }

    &:focus-visible {
      border: 1px solid rgba(theme.$brand-dark, 0.9);
      -webkit-box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
      box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
    }

  } @else if $variance == "secondary" {
    border-radius: theme.$bubble-chat-border-radius theme.$bubble-chat-border-radius theme.$bubble-chat-border-radius 4px;
    background-color: white;
    color: theme.$main-brand;
    border: 1px solid theme.$main-brand;
    padding: 14px 30px;
    &:hover {
      cursor: pointer;
      -webkit-box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
      box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
      color: theme.$brand-dark;
      border-color: theme.$brand-dark;
    }
    &:focus-visible {
      border: 1px solid rgba(theme.$brand-dark, 0.9);
      -webkit-box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
      box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
    }


  } @else if $variance == "tertiary" {
    background: transparent;
    outline: none;
    text-decoration: underline;
    border: none;
    color: theme.$main-brand;
    padding: 14px 30px;
    &:hover {
      cursor: pointer;
      -webkit-text-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
      text-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
      color: theme.$brand-dark;
    }
    &:focus-visible {
      border: 1px solid rgba(theme.$brand-dark, 0.9);
      -webkit-box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
      box-shadow: 0 0 3px 3px rgba(theme.$main-brand, 0.4);
    }
  }
}

