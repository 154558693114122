@use 'presets' as *;
@use '../../styles/partial/theme' as theme;
@use '../../styles/partial/mixin' as mixin;

#pb-message-container {
  .pb-bot-response, .pb-user-input {
    padding: 0 25px;
    font-family: $primary-font;
    font-size: $primary-font-size;
    letter-spacing: .4;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    //touch-action: none;
  }

  .metadata {
    align-self: flex-end;
    width: 100%;

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 10px 0;

      .metadata-link {
        margin-right: 6px;
      }

      i {
        cursor: pointer;
      }
    }

    .pb-metadata__trace-link {
      margin-top: 10px;
    }

    .metadata-block {
      width: 100%;
    }
  }

  .pb-user-input {
    align-items: flex-end;
    &--log {
      align-items: flex-start;
    }
  }

  .pb-bot-response {
    align-items: flex-start;
    &--log {
      align-items: flex-end;
    }
  }

  .pb-buttonList {
    &__container {
      //display: flex;
      //flex-direction: column;
      //gap: theme.$button-gap;
      @include mixin.button-container-style();

      button {
        padding: 9px;
        border: none;
        background-color: white;
        cursor: pointer;
        font-family: $primary-font;
        font-size: $button-font-size;
        flex: 1 1 auto;
      }

      button:first-of-type {
        border: $thin-border;
        border-bottom: none;
        border-radius: 0 20px 0 0;
      }

      button:not(:first-of-type) {
        border: $thin-border;
        border-bottom: none;
      }

      button:last-of-type {
        border: $thin-border;
        border-radius: 0px 0px 20px 20px;
      }

    }
  }
}
