#pb-message-container {
  .pb-standalone-image {
    width: 90%;
    height: auto;
  }
  .pb-fullSizeImage {
    width: 100%;
    height: auto;
    min-width: 225px;
  }
}
