@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&display=swap');

$pandorabots-color: #7C4DFF;
$pandorabots-font: Comfortaa;
$border-color: #ddd;
$box-shadow: 0px 2px 10px 1px #b5b5b5;
$primary-font: "Open Sans", Arial;
$primary-font-size: 15px;
$button-font-size: 15px;
$reply-font-size: $button-font-size;
$thin-border: 1px solid $border-color;

//color scheme
$pb-purple: #7C4DFF;
$dark-purple: #651FFF;
$medium-purple: #E1D9FF;
$light-purple: #EEEBFF;
$red: #FF0033;
$dark-red: #CC0033;
$orange: #FF9D00;
$yellow: #F9ED12;
$green: #25C125;
$white: #FFFFFF;
$black: #434343;
$midnight: #000000;
$watermark-gray: #F8F8F8;
$light-gray: #E8E8E8;
$medium-gray: #9A9A9A;
