@use '../../../../styles/partial/media' as media;
@use '../../../../styles/partial/theme' as theme;

*, *:before, *:after {
  box-sizing: border-box;
}

.g-sign-in-button {
  margin: 0 0 0 0;
  display: inline-block;
  height: 46px;
  background-color: rgba(theme.$google-up-bg-color,1);
  color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  text-align: left;
  width:100%;

  &:hover,
  {
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px 3px theme.$google-hover-box-shadow-color;
    box-shadow: 0 0 3px 3px  theme.$google-hover-box-shadow-color;
    background-color:  theme.$google-hover-bg-color;
  }

  &:active {
    background-color:  theme.$google-active-bg-color;
    transition: background-color 0.2s;
  }

  &:focus{
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px 4px theme.$google-hover-box-shadow-color;
    box-shadow: 0 0 3px 4px  theme.$google-hover-box-shadow-color;
  }
}


.g-sign-in-button .content-wrapper {
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
}

.g-sign-in-button img {
  width: 20px;
}

.g-sign-in-button .logo-wrapper {
  padding: 10px 11px;
  background: #fff;
  width: 46px;
  height: 100%;
  border-radius: 2px;
  display: inline-block;
}

.g-sign-in-button .text-container {
  font-family: Roboto,arial,sans-serif;
  font-weight: 500;
  letter-spacing: .21px;
  font-size: 16px;
  line-height: 44px;
  vertical-align: top;
  border: none;
  text-align: center;
  padding-left:6px;

  @media(min-width: media.$mobile-portrait-tiny-width) and (max-width: media.$mobile-portrait-width - 1){
    padding-left:10px;
    font-size:15px;
    display: inline-block;
  }

  @media(min-width: media.$mobile-portrait-width){
    padding-left:20px;
    font-size:16px;
    display: inline-block;
  }
}
